.user-level-progress {
    width: 100%;
    $borderRadius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;

    .info-tooltip {
        opacity: 0.35;
        transition: opacity 0.3s ease;
        position: absolute;
        top: 2px;
        right: 5px;

        &:hover {
            opacity: 1;
        }

        .icon {
            font-size: 0.8rem;
        }

        .tooltip {
            line-height: 1.1rem;
        }
    }

    .total {
        overflow: hidden;
        border-radius: $borderRadius;
        width: 100%;
        height: 1.3rem;
        background-color: rgba(0, 0, 0, 0.5);
        padding: 2px;
        position: relative;
        text-align: center;
        justify-content: center;

        .progress {
            height: 100%;
            border-radius: $borderRadius - 1;
            background-color: #86e01e;
            background: linear-gradient(0deg, #86e01e, #67b219);
        }

        .points {
            padding-top: 2px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            right: 0;
            left: 0;
            top: 0;
            bottom: 0;
            font-size: 0.7rem;
            text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.9);
        }
    }

    .total-points {
        margin-top: 1px;
        margin-left: 0px;
        font-size: 0.65rem;
        color: #fff;
        display: flex;
        align-items: center;
        position: relative;
        top: 1px;
        opacity: 0.8;
        text-align: center;

        .icon {
            font-size: 0.55rem;

            margin-left: 1px;
            margin-right: 4px;
        }
    }
}
