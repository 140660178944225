@import '../../../../variables';

#page-game-playfield {
    @extend .noselect;

    position: relative;

    .play-container {
        width: 100%;
        height: 100%;
        margin-top: -1.5rem;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;

        .game-field {
            display: flex;
            height: 100%;
            width: 100%;
            margin: 1rem auto 0;
        }
    }

    .page-cross {
        right: 0;
        top: -3rem;
    }
}

@media (max-width: 768px) {
    #page-game-playfield {
        position: relative;
        .page-cross {
            display: none;
        }

        .play-container {
            margin: 0;

            .game-field {
                width: 99%;
                margin: 0.5rem auto 0;
            }
        }
    }
}

@media (max-width: 320px) {
    #page-game-playfield {
        .page-cross {
            right: 20px;
            top: -35px;
        }
    }
}
