#notification-wrapper {
  position: absolute;
  top: 20px;
  right: 10px;
  z-index: 100;
  width: 250px;

  .wrapper {
    height: 0;
    opacity: 0;
    background-color: rgba(255, 255, 255, 0.85);
    transition: height 0.3s ease, opacity 0.3s ease;
    border-radius: 4px;
    border: 2px solid #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  }

  .notification-enter-done,
  .notification-enter-active {
    height: 60px;
    opacity: 1;
  }

  .notification-exit-active {
    height: 0;
    opacity: 0;
  }
}
