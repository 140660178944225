#app {
    width: 100vw;
    height: 100%;
}

.app-bg {
    background-image: url('../../images/background.png');
    background-size: 180px;
    opacity: 0.03;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
}
