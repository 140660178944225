.buttons-group {
  display: flex;
  justify-content: center;
  align-items: center;

  .group-button:first-child {
    border-radius: 8px 0 0 8px;
  }

  .group-button:last-child {
    border-radius: 0 8px 8px 0;
  }
}

