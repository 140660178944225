$blockWidth: 4.5rem;

#top-users {
    .header {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 0.5rem;
        margin-bottom: 0.9rem;

        img {
            width: $blockWidth;
        }

        span {
            display: block;
            margin-top: -5px;
            font-size: 0.8rem;
            text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
        }
    }

    .users {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        flex: 1;

        .top-user {
            width: $blockWidth;
            margin: 0.5rem 0 0.6rem;
            text-align: center;
            display: flex;
            align-items: center;
            flex-direction: column;
            cursor: pointer;

            .user-photo {
                width: $blockWidth - 1rem;
                height: $blockWidth - 1rem;
                border-radius: 8px;
                overflow: hidden;
                border: 1px solid rgba(255, 255, 255, 0.8);
                transition: transform 0.4s ease;

                span {
                    width: 100%;
                    height: 100%;
                    display: block;
                    background-size: cover;
                    background-repeat: no-repeat;
                }
            }

            .user-name {
                margin-top: 0.3rem;
                font-size: 0.75rem;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            &:hover {
                .user-photo {
                    transform: scale(1.05);
                }
            }
        }
    }
}
