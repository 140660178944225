@import '../../../../variables';

$userPadding: 0.4rem;

.hr {
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
    max-width: 240px;
    margin: 1.1rem auto;
}

.game-container {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .game-result {
        display: flex;
        flex-direction: row;

        .score {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 2.7rem;
            padding: 0 0.8rem;
            letter-spacing: 0.4rem;
            white-space: nowrap;
            flex-direction: column;
            font-weight: bold;
        }

        .user {
            display: flex;
            width: 13rem;

            .user-game-info {
                display: flex;
                flex-direction: column;
                justify-content: center;
                max-width: 8rem;

                .user-name {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font-size: 1.25rem;
                }

                .points {
                    white-space: nowrap;
                    margin-top: 4px;
                    font-size: 0.75rem;
                    opacity: 0.8;

                    .icon {
                        position: relative;
                        top: -1px;
                        font-size: 0.65rem;
                        margin-right: 4px;
                    }
                }
            }
        }

        .first-user {
            padding-right: $userPadding;
            justify-content: flex-end;

            .user-game-info {
                text-align: right;
                margin-right: 0.8rem;
            }
        }

        .second-user {
            padding-left: $userPadding;

            .user-game-info {
                text-align: left;
                margin-left: 0.8rem;
            }
        }
    }

    .game-info {
        margin-bottom: 0.3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        font-size: 0.75rem;

        .rounds {
            text-align: center;
            margin-top: 4px;
            letter-spacing: 0;
            font-size: 0.7rem;
            line-height: 0.5;
            opacity: 0.8;
        }

        .date {
            .icon {
                margin-right: 5px;
                font-size: 0.7rem;
            }
        }
    }
}

@media (max-width: 768px) {
    .game-container {
        .game-result {
            .user {
                .user-game-info {
                    margin: 5px 0 0 0;
                    .user-name {
                        font-size: 1.1rem;
                    }
                }
            }
            .first-user {
                flex-direction: column-reverse;
                align-items: flex-end;
            }
            .second-user {
                flex-direction: column;
                align-items: flex-start;
            }
        }
    }
}
