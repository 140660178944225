.dialog-alert {
    .error-block {
        padding: 1.5rem;

        .message {
            text-align: center;
            font-size: 1.3rem;
        }
    }
}
