.dialog-user-disconnected {
    .content {
        width: 90%;
        margin: 0 auto;
        padding-bottom: 0.2rem;

        .game-info {
            padding: 0.5rem 0 0.5rem;
            font-size: 6rem;
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: center;
            color: #af6565;
        }

        .text-block {
            line-height: 1.3;
            text-align: center;
            margin: 0.5rem 0;
        }
    }

    .buttons {
        margin-top: 0.5rem !important;
    }
}
