$color: #69aa2f;

.shop-item {
  background-color: $color;
  padding: 0.5rem 0.8rem;
  margin: 5px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 8px;
  border: 2px solid rgba(255, 255, 255, 0.5);
  cursor: pointer;
  transition: background-color 0.3s ease;
  position: relative;

  .loading-block {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    display: none;
  }

  &:hover {
    background-color: lighten($color, 3%);
  }

  &:active {
    background-color: darken($color, 3%);
  }

  & > .title {
    font-size: 1.2rem;
  }

  & > .description {
    margin-top: 4px;
    font-size: 0.8rem;
  }

  &.is-loading {
    cursor: none;
    pointer-events: none;

    & > .title, & > .description {
      opacity: 0.3;
    }

    .loading-block {
      display: flex;
    }
  }

  &.is-disabled {
    cursor: none;
    pointer-events: none;

    & > .title, & > .description {
      opacity: 0.3;
    }
  }
}