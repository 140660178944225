@import '../../../variables';

#page-profile {
    .user-container {
        margin: 1rem 0 0 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;

        .user-progress-block {
            display: flex;
            flex-direction: column;
            max-width: $profilePageWidth;
            width: 100%;
            flex-shrink: 0;

            .photo-wrapper {
                margin: 0 0 1rem 0;
                display: flex;
                align-items: center;
                justify-content: center;

                .user-info {
                    flex-shrink: 0;
                    .user-photo {
                        width: 7.5rem;
                        height: 7.5rem;
                        border-width: 2px;
                    }
                }
            }

            .location {
                margin: -0.4rem 0 0.7rem 0;
                font-size: 1rem;
                display: flex;
                align-items: center;
                justify-content: center;

                .icon {
                    font-size: 0.9rem;
                    margin-right: 0.3rem;
                }

                span {
                    position: relative;
                    top: 1px;
                }
            }

            .user-info-block {
                display: flex;
                flex-direction: column;
                width: 100%;
            }

            .progress-block {
                display: flex;
                flex-direction: column;
                width: 100%;

                .user-level-progress {
                    .total-points {
                        font-size: 0.7rem;
                        opacity: 1;
                        margin-top: 3px;
                        top: 0;
                        align-items: flex-end;
                        justify-content: center;

                        .icon {
                            font-size: 0.7rem;
                        }
                    }
                }

                .info {
                    margin-top: 0.8rem;
                    display: flex;
                    justify-content: space-between;
                }

                .progress-item {
                    .value {
                        font-size: 0.9rem;

                        .icon {
                            margin-right: 0.3rem;
                        }
                    }
                    .text {
                        text-transform: uppercase;
                        font-size: 0.55rem;
                        margin-top: 4px;
                    }
                }
            }
        }

        .profile-buttons {
            margin: 1rem auto 0;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row;
            flex-shrink: 0;
            width: 55%;
            flex-wrap: wrap;

            .app-button {
                margin: 0 0.2rem 0.2rem;
                border-width: 2px;
                transition: transform 0.2s ease;
                width: 45%;

                &:hover {
                    transform: scale(1.05);
                    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
                }

                .internal {
                    .icon {
                        font-size: 0.8rem;
                        width: 1rem;
                        left: -4px;
                    }
                    .text {
                        font-size: 0.75rem;
                    }
                }

                &.invite {
                    background-color: $inviteColor;
                }

                &.add-question {
                    background-color: #00a9a1;
                }
            }
        }
    }

    .last-games {
        margin-top: 1.5rem;
        width: 80%;

        h2 {
            font-size: 1.35rem;
            text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
            margin-bottom: 1.1rem;
            text-align: center;
        }

        .no-games {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            padding: 0.5rem 0;

            .icon {
                font-size: 3rem;
            }

            .text {
                margin-top: 0.8rem;
                font-size: 0.9rem;
            }
        }
    }
}

@media (max-width: 768px) {
    #page-profile {
        width: auto;

        & .user-container {
            .user-progress-block {
                .photo-wrapper {
                    .user-info {
                        .user-photo {
                            width: 9rem;
                            height: 9rem;
                        }
                    }
                }

                width: 95%;

                .progress-block {
                    .progress-item {
                        .value {
                            font-size: 0.85rem;

                            .icon {
                                margin-right: 4px;
                            }
                        }
                        .text {
                            text-transform: uppercase;
                            font-size: 0.6rem;
                            margin-top: 3px;
                        }
                    }
                }
            }

            .profile-buttons {
                width: 90%;
            }
        }

        .last-games {
            width: 96%;
        }
    }
}

@media (max-width: 320px) {
    #page-profile {
        width: auto;

        & .user-container {
            .user-progress-block {
                .photo-wrapper {
                    .user-info {
                        .user-photo {
                            width: 7.5rem;
                            height: 7.5rem;
                        }
                    }
                }
            }
        }
    }
}
