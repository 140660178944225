.user-info {
    position: relative;

    &.is-active {
    }

    &.big {
        .user-photo {
            width: 8rem;
            height: 8rem;
            border-width: 2px;
        }

        .user-level {
            min-width: 20px;
            min-height: 20px;
            font-size: 0.8rem;
            padding: 2px;
            bottom: -5px;
            right: -5px;
        }
    }

    &.medium {
        .user-photo {
            width: 3.8rem;
            height: 3.8rem;
            border-width: 2px;
        }

        .user-level {
            min-width: 20px;
            min-height: 20px;
            font-size: 10px;
            padding: 2px;
            bottom: -5px;
            right: -5px;
        }
    }

    &.small {
        .user-photo {
            width: 3.8rem;
            height: 3.8rem;
            border-width: 1px;
        }

        .user-level {
            min-width: 20px;
            min-height: 20px;
            font-size: 10px;
            padding: 2px;
            bottom: -5px;
            right: -5px;
        }
    }

    .user-photo {
        width: 5.7rem;
        height: 5.7rem;
        position: relative;
        border-radius: 8px;
        border: 3px solid rgba(255, 255, 255, 1);
        overflow: hidden;

        .image {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            background-size: cover;
            background-position: center;
            z-index: 0;
            background-color: rgba(255, 255, 255, 0.3);
        }
    }

    .user-level {
        position: absolute;
        bottom: -7px;
        right: -7px;
        z-index: 1;
        padding: 3px;
        border-radius: 5px;
        background-color: rgba(253, 82, 43, 0.95);
        font-size: 0.8rem;
        border: 2px solid #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 25px;
        min-height: 25px;
    }

    .badge {
        position: absolute;

        z-index: 1;
        padding: 3px;
        border-radius: 5px;
        background-color: rgba(0, 206, 17, 0.95);
        font-size: 0.8rem;
        border: 1px solid #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 20px;
        min-height: 20px;

        &.boost {
            top: 40px;
            left: -10px;
        }

        &.bonuses {
            top: 5px;
            left: -10px;
            flex-direction: column;

            span {
                font-size: 0.65rem;
                margin-top: 0.1rem;
            }
        }
    }

    .user-text {
        color: #fff;
    }
}
