.dialog-see-questions {
    padding: 1rem 0 0.4rem;

    .content {
        max-width: 40rem;
        min-height: 400px;
        min-width: 630px;
        width: 90%;
        margin: 0 auto;
        padding-bottom: 0.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .filter-menu {
            display: flex;
            width: 100%;
            margin-bottom: 10px;
            justify-content: center;
            font-size: 13px;

            .item {
                margin: 0 5px;
                cursor: pointer;
                color: #999;

                &.is-active {
                    color: #444;
                    text-decoration: underline;
                }
            }
        }
        .questions {
            height: 400px;
            overflow-y: auto;

            .question {
                padding: 10px 7px;
                transition: background ease 0.2s;

                &:nth-child(odd) {
                    background: #f9f9f9;
                }

                &:hover {
                    background: #f1f1f1;
                }

                .sub-info {
                    margin-top: 6px;
                    font-size: 11px;
                    color: #888;

                    span {
                        margin-right: 10px;

                        i {
                            font-weight: 400;
                            &.yes {
                                color: green;
                            }

                            &.no {
                                color: #ab0000;
                            }
                        }
                    }
                }

                .title-block {
                    display: flex;
                    flex-direction: row;

                    .title {
                        width: 82%;
                        display: flex;
                        align-items: center;
                        font-size: 0.92rem;
                        line-height: 1.05rem;
                    }
                    .action {
                        display: flex;
                        width: 18%;
                        justify-content: space-around;
                    }
                }
            }
        }
    }

    .buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-top: 0.5rem !important;
    }
}
