#page-rating {
    .filter {
        margin-top: 5px;

        .group-button {
            .internal {
                text-transform: uppercase;
                padding: 0.25rem 1rem;
            }
        }
    }

    .users-list {
        margin: 0.5rem auto;
        padding: 0.5rem 0;
        height: 100%;
        width: 72%;
        overflow: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }
}

@media (max-width: 768px) {
    #page-rating {
        .filter {
            .group-button {
                .internal {
                    padding: 0.25rem 0.5rem;
                }
            }
        }

        .users-list {
            width: auto;
            margin: 0.3rem auto;
        }
    }
}

@media (max-width: 320px) {
    #page-rating {
        .users-list {
            margin: 0;
        }
    }
}
