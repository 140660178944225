@import '../../../../variables';

$userPadding: 1rem;
$avatarPadding: 1.5rem;

#multiplayer-game-panel {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    position: relative;

    .users {
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: center;
        justify-content: center;

        .round {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            .score {
                font-size: 3rem;
                padding: 0 1rem;
                letter-spacing: 0.7rem;
                white-space: nowrap;
                flex-direction: column;
                font-weight: bold;
            }

            .round-value {
                margin-top: 0.1rem;
                text-transform: uppercase;
                font-size: 0.6rem;
                color: rgba(255, 255, 255, 0.5);
            }
        }

        .user {
            display: flex;
            width: 100%;

            .user-info {
                .user-photo {
                    width: 5.3rem;
                    height: 5.3rem;
                }
            }

            .user-game-info {
                display: flex;
                flex-direction: column;
                justify-content: center;

                .user-name {
                    white-space: nowrap;
                    font-size: 1.6rem;
                }

                .games-info {
                    display: flex;
                    flex-direction: row;
                    margin-top: 0.35rem;

                    .info-item {
                        margin-right: 1rem;
                        display: flex;
                        align-items: center;
                        opacity: 0.8;

                        &.games-win {
                            .icon {
                                top: 0;
                            }
                        }

                        &.games-played {
                            .icon {
                                top: 0;
                            }
                        }

                        .icon {
                            font-size: 1rem;
                            margin-right: 4px;
                            position: relative;
                        }

                        span {
                            display: block;
                            font-size: 0.9rem;
                        }
                    }
                }
            }
        }

        .first-user {
            padding-right: $userPadding;
            justify-content: flex-end;

            .user-game-info {
                text-align: right;
                margin-right: $avatarPadding;

                .games-info {
                    flex-direction: row-reverse;
                    justify-content: flex-end;

                    .info-item {
                        margin-right: 0;
                        margin-left: 1rem;
                    }
                }
            }
        }

        .second-user {
            padding-left: $userPadding;

            .user-game-info {
                text-align: left;
                margin-left: $avatarPadding;
            }
        }
    }

    .notification-block {
        position: absolute;
        overflow: hidden;
        bottom: -1.8rem;
        font-size: 0.8rem;
        height: 1.3rem;
        display: flex;
        align-items: flex-end;

        .in-notification {
            .notification-content {
                padding: 3px 5px;
                background-color: rgba(0, 0, 0, 0.1);
                border-radius: 3px;

                .icon {
                    font-size: 0.7rem;
                    margin-right: 5px;
                    vertical-align: middle;
                }

                span {
                    vertical-align: middle;
                }
            }
        }

        .action-appear {
            opacity: 0.1;
            transform: translateY(-10px);
        }

        .action-appear.action-appear-active {
            opacity: 1;
            transform: translateY(0);
            transition: opacity 200ms ease-in, transform 200ms ease-in;
        }

        .action-enter {
            opacity: 0.1;
            transform: translateY(10px);
        }

        .action-enter.action-enter-active {
            opacity: 1;
            transform: translateY(0);
            transition: opacity 200ms ease-in, transform 200ms ease-in;
        }

        .action-exit {
            opacity: 1;
            position: absolute;
        }

        .action-exit.action-exit-active {
            opacity: 0;
            transform: translateY(10px);
            transition: opacity 200ms ease-in, transform 200ms ease-in;
        }
    }
}

@media (max-width: 768px) {
    #multiplayer-game-panel {
        .users {
            padding-top: 0.35rem;

            .round {
                .score {
                    font-size: 2rem;
                    padding: 0 0.5rem;
                }
            }
            .user {
                .user-info {
                    width: 3.5rem;
                    height: 3.5rem;
                    .user-photo {
                        width: 3.5rem;
                        height: 3.5rem;
                        border-width: 2px;
                    }
                }

                .user-game-info {
                    margin: 0.35rem 0 0 0;

                    .user-name {
                        font-size: 1.1rem;
                        font-weight: 400;
                    }

                    .games-info {
                        margin: 0.2rem 0 0 0;
                        .info-item {
                            span {
                                font-size: 0.7rem;
                            }
                            .icon {
                                font-size: 0.75rem;
                            }
                        }
                    }
                }
            }
            .user.first-user {
                padding-right: 0.5rem;
                flex-direction: column-reverse;
                align-items: flex-end;
            }

            .user.second-user {
                padding-left: 0.5rem;
                flex-direction: column;
            }
        }
    }
}
