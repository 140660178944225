#dialog {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.2);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;

    .dialog-animation {
        transform: translateY(-5%);
    }

    .dialog-layout {
        display: flex;
        min-width: 400px;
        min-height: 250px;
        border-radius: 20px;
        border: 10px solid rgba(255, 255, 255, 0.5);
        transform: translateY(-5%);
        transition: transform 0.5s ease;
        overflow: hidden;
        box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.4);

        .dialog {
            color: #000;
            min-height: 100%;
            min-width: 100%;
            background-color: #fff;

            .dialog-content {
                h2 {
                    text-align: center;
                    font-size: 2.1rem;
                    margin: 0.5rem 0 0.8rem;
                    color: #b93a0e;
                    text-shadow: none;
                    font-weight: bold;
                }
            }
        }
    }

    .dialog-appear {
        opacity: 0.2;
        transform: scale(0.3);
    }

    .dialog-appear.dialog-appear-active {
        opacity: 1;
        transform: scale(1);
        transition: opacity 300ms ease, transform 300ms ease;
    }

    .dialog-exit {
        opacity: 1;
    }

    .dialog-exit.coutndown-exit-active {
        opacity: 0.1;
        transform: scale(0.8);
        transition: opacity 300ms ease, transform 300ms ease;
    }
}

@media (max-width: 768px) {
    #dialog {
        .dialog-animation {
            transform: translateY(0);
            width: 95%;
        }

        .dialog-layout {
            min-width: auto;
        }
    }
}

@media (max-width: 1000px) {
    .dialog-animation {
        transform: translateY(-20%);
    }
}
