@import './variables';

.yandex-games {
    @extend .noselect;
}

@media (min-width: 1100px) {
    .yandex-games {
        font-size: 110%;

        #home {
            height: 92vh;
            width: 90vw;

            .page-content .content-wrapper .content {
                padding-left: 3vw;
            }

            header .user-header {
                width: 100%;
            }
        }
    }
}

@media (min-width: 1370px) {
    .yandex-games {
        font-size: 120%;

        #home {
            height: 92vh;
            width: 91vw;

            .page-content .content-wrapper .content {
                padding-left: 3vw;
            }

            header .user-header {
                width: 100%;

                .user-level-progress {
                    width: 16rem;
                    margin-left: 8rem;
                }

                .user-money {
                    margin-left: 2.5rem;
                }
            }
        }

        #home.is-game-started .page-content .content-wrapper > .content {
            padding: 0;
        }

        #page-default {
            .menu.sub-menu .app-button {
                height: 4.5rem;

                .text {
                    font-size: 1.8rem;
                }
            }

            .menu.main-menu .app-button {
                width: 22rem;
                height: 4.5rem;

                .text {
                    font-size: 1.8rem;
                }
            }

            .menu.sub-menu {
                margin-top: 1.8rem;
            }
        }

        .money-item .description {
            .title {
                font-size: 1.4rem;
            }

            .price {
                font-size: 1rem;
            }
        }

        .rating-user {
            padding: 0.65rem 0.9rem;
        }

        .user-progress-block .progress-block .progress-item .value {
            font-size: 1.2rem !important;
        }

        .app-button.small-inline {
            height: 3rem;

            .text {
                font-size: 1rem !important;
            }
        }

        #page-help .textBlock {
            width: 70%;
            margin: 0 auto;
        }

        #questions-answers .question-wrapper {
            height: 60%;
        }

        #questions-answers .question-wrapper .question span {
            font-size: 1.8rem;
            line-height: 1.2;
            width: 80%;
        }

        #questions-answers .answers .answer-wrapper .answer .text {
            font-size: 1.5rem;
        }
    }
}
