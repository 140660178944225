.app-button {
    line-height: 1;
    color: #fff;
    background: #69aa2f;
    cursor: pointer;
    min-width: 120px;
    overflow: hidden;
    position: relative;
    border-radius: 6px;
    transition: transform 0.3s ease;
    border: 2px solid rgba(255, 255, 255, 0.2);

    &.is-loading {
        & > .internal {
            opacity: 0.2;
        }

        & > .loading-block {
            display: flex;
        }
    }

    & > .loading-block {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        display: none;
    }

    & > .internal {
        padding: 0.1rem 0.2rem;
        height: 2.7rem;
        position: relative;
        z-index: 10;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .text {
            min-width: 50%;
            text-align: left;
            font-size: 1rem;
        }
    }

    & .icon {
        font-size: 1rem;
        left: -10px;
        position: relative;
    }

    &.smaller-inline {
        min-width: 0;
        height: 1.5rem;

        & > .internal {
            height: 0.9rem;
        }

        .icon {
            width: 1rem;
        }

        .text {
            font-size: 0.75rem;
        }
    }

    &.small {
        font-size: 1rem;
    }

    &.small-inline {
        height: 2rem;

        & > .internal {
            height: 1rem;
        }

        .icon {
            width: 1.2rem;
        }
    }

    &.medium {
        font-size: 1.1rem;

        .internal {
            height: 3.5rem;
        }
    }

    &.big {
        font-size: 1.3rem;
    }

    //

    &:after,
    &:before {
        padding: 18px 0 11px;
        content: '';
        position: absolute;
        top: 0;
        left: calc(-120%);
        height: calc(100% - 29px);
        width: calc(100% + 21px);
        color: #fff;
        border-radius: 2px;
        transform: skew(-25deg);
    }

    &:after {
        background: rgba(255, 255, 255, 0.2);
        transition: left 0.4s cubic-bezier(0.86, 0, 0.07, 1) 0.2s;
        z-index: 0;
        opacity: 0.8;
    }

    &:hover:after {
        left: calc(50%);
        transition: left 0.6s cubic-bezier(0.86, 0, 0.07, 1) 0.2s;
    }
}
