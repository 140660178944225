#app-loading {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .app-loading-wrapper {
        display: flex;
        width: 15rem;
        height: auto;
        align-items: center;
        flex-direction: column;
        padding: 5px;
        margin-top: -50px;

        .logo {
            height: 150px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
            padding-bottom: 0.5rem;
            margin-bottom: 1rem;

            img {
                height: 100%;
            }
        }

        .text {
            font-size: 1rem;
            animation: fading 2s infinite;
        }

        @keyframes fading {
            0% {
                opacity: 0.5;
            }
            50% {
                opacity: 1;
            }
            100% {
                opacity: 0.5;
            }
        }
    }

    .app-loading {
        margin: 10px 0;
    }
}
