#page-game-countdown {
  justify-content: center;
  align-items: center;

  & > div {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .value {
    font-size: 15rem;
    position: absolute;
    left: 0;
    right: 0;
    top:0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .coutndown-appear {
    opacity: 0.1;
    transform: scale(0.2);
  }

  .coutndown-appear.coutndown-appear-active {
    opacity: 1;
    transform: scale(1);
    transition: opacity 500ms ease-in, transform 500ms ease-in;
  }

  .coutndown-enter {
    opacity: 0.1;
    transform: scale(0.9);
  }

  .coutndown-enter.coutndown-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in, transform 500ms ease-in;
    transform: scale(1);
  }

  .coutndown-exit {
    opacity: 1;
    position: absolute;
  }

  .coutndown-exit.coutndown-exit-active {
    opacity: 0.1;
    transform: scale(2.1);
    transition: opacity 600ms ease-in, transform 600ms ease-in;
  }
}

@media (max-width: 320px)  {
  #page-game-countdown {
    .value {
      font-size: 10rem;
    }
  }
}
