@import '../../variables';

#auth-page {
    width: $contentWidth;
    height: 90%;
    margin: 0 auto;
    overflow: hidden;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .auth-block {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        flex-direction: column;

        .app-logo {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 0 15px;

            img {
                max-width: 350px;
            }
        }

        .blocker {
            display: none;
            background-color: rgba(0, 0, 0, 0.4);
            border-radius: 8px;
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            z-index: 20;
            justify-content: center;
            align-items: center;
        }

        &.is-blocked {
            pointer-events: none;

            .blocker {
                display: flex;
            }
        }

        .methods {
            margin-top: 1rem;
            margin-bottom: 2rem;
            position: relative;
            width: 400px;
            padding: 1.5rem 1rem 2rem;
            background-color: rgba(255, 255, 255, 0.1);
            border-radius: 8px;

            h2 {
                text-align: center;
                font-size: 2.5rem;
                margin-bottom: 2rem;
                text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.2);
            }

            .buttons {
                margin-top: 1.5rem;
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;

                .app-button {
                    display: block;
                    margin: 0 0 10px;
                    width: 250px;
                    border: 0;

                    .internal {
                        .icon {
                            font-size: 1.5rem;
                        }
                    }
                    &.vk {
                        background-color: #45668e;
                    }

                    &.fb {
                        background-color: #3b5998;
                    }

                    &.ok {
                        background-color: #ed812b;
                    }
                }
            }
        }
    }
}

@media (max-width: 768px) {
    #auth-page {
        width: auto;
        height: 100%;

        .auth-block {
            justify-content: space-evenly;

            .methods {
                border-radius: 0;
                background-color: transparent;
                border: 0;

                .buttons {
                    .app-button {
                        border: 2px solid rgba(255, 255, 255, 0.8);
                    }
                }
            }
        }
    }
}

@media (max-width: 320px) {
    #auth-page {
        width: auto;
        height: 100%;

        .auth-block {
            justify-content: space-evenly;

            .app-logo {
                img {
                    height: 90px;
                }
            }
            .methods {
                margin: 0;
            }
        }
    }
}
