.helper-item {
    width: 3.3rem;
    height: 3.3rem;
    font-size: 1.4rem;
    margin: 0 10px;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;
    text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.2);
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 10px;
    border: 2px solid rgba(255, 255, 255, 0.5);
    transition: transform 0.3s ease;

    &.is-disabled {
        opacity: 0.5;
    }

    &.is-active:hover {
        transform: scale(1.2);
    }

    .text {
        display: flex;
        align-items: center;
        position: relative;
        top: 0.09rem;

        span {
            font-size: 1.7rem;
        }
    }

    .icon {
        font-size: 1.7rem;
    }
}

@media (max-width: 768px) {
    .helper-item {
        width: 2.8rem;
        height: 2.8rem;
    }
}
