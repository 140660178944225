@import '../../../../variables';

#page-game-friend {
    .content {
        display: flex;
        margin-top: 2rem;
        justify-content: flex-start;
        align-items: flex-start;

        .helper {
            position: absolute;
            text-align: center;
            font-size: 0.8rem;
            line-height: 1.3;
            left: 0;
            right: 0;
            bottom: 2rem;

            & > span {
                width: 60%;
                display: block;
                margin: 0 auto;
            }
        }

        .game-friend-block {
            display: flex;
            height: 100%;
            justify-content: center;

            & > .image {
                width: 40%;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-direction: column;
                margin-top: 40px;

                img {
                    width: 75%;
                }
            }

            & > .info {
                width: 60%;
                padding: 1rem 2.5rem;

                .error-message {
                    font-size: 0.85rem;
                    margin: 3px 0;
                    text-align: center;
                }

                & > .text {
                    text-align: center;
                    font-size: 1.3rem;
                    line-height: 1.25;
                    margin-bottom: 1.5rem;

                    .pin {
                        margin-top: 15px;
                        display: flex;
                        flex-direction: column;

                        .pin-value {
                            font-size: 2.5rem;
                            text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
                        }

                        .pin-helper {
                            margin-top: -0.1rem;
                            font-size: 0.7rem;
                            opacity: 0.9;
                        }
                    }

                    .code {
                        margin-top: 20px;

                        input {
                            border: 2px solid #fff;
                            padding: 0.1rem 0.5rem;
                            text-align: center;
                            width: 60%;
                            margin: 0 auto;
                            font-size: 2.3rem;
                        }
                    }
                }

                & > .buttons {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    .app-button {
                        margin: 0 0 0.7rem;
                        width: 100%;

                        .internal .text {
                            text-align: center;
                        }

                        &.connect {
                            background-color: #028e9b;
                        }

                        &.invite {
                            background-color: $inviteColor;
                        }
                    }

                    .link {
                        margin-top: 5px;
                        cursor: pointer;
                        opacity: 0.8;
                        transition: opacity 0.3s ease;
                        font-size: 0.9rem;
                        border-bottom: 1px solid #fff;
                        padding-bottom: 2px;

                        &:hover {
                            opacity: 1;
                        }
                    }
                }

                & > .game-link {
                    margin-top: 2rem;
                    text-align: center;

                    .link-wrapper {
                        position: relative;
                        display: flex;
                        align-items: center;

                        .link-value {
                            font-size: 1.78rem;
                            background: transparent;
                            border: 0;
                            text-align: right;
                            color: #fff;
                            padding-right: 5px;
                            width: 303px;
                        }

                        i {
                            font-size: 1rem;
                            position: relative;
                            top: -10px;
                            cursor: pointer;
                        }
                    }

                    .link-text {
                        margin-top: 0px;
                        font-size: 0.8rem;
                    }
                }
            }
        }
    }
}

@media (max-width: 768px) {
    #page-game-friend {
        .content {
            padding-left: 0 !important;

            .game-friend-block {
                align-items: center;
                width: 100%;

                & > .image {
                    display: none;
                }

                & > .info {
                    width: auto;
                    transform: translateY(-25%);
                    padding: 1rem;

                    & > .text {
                        font-size: 1.2rem;
                        line-height: 1;
                        margin-bottom: 1.2rem;
                    }

                    .link-value {
                        width: auto !important;
                    }
                }
            }

            .helper {
                & > span {
                    width: 80%;
                }
            }
        }
    }
}
