@import '../../../variables';

$topButtonHeight: 3.7rem;

#page-default {
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;

    .app-logo {
        width: 100%;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: -40px;
        //display: none;

        img {
            height: 100%;
        }
    }

    .main-block {
        width: 100%;
        height: 100%;
        padding: 2rem 0 0 0;
        display: flex;

        & > .wrapper {
            background-color: rgba(255, 255, 255, 0.05);
            border-radius: 10px;
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }

        .hello-block {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            padding: 0;
            margin: 1rem 0 0.4rem;

            .user-photo {
                width: 9rem;
                height: 9rem;
            }

            .hello {
                margin: 0.8rem 0 0;
                font-size: 2rem;
            }
        }

        .into {
        }
    }

    .menu {
        margin-top: 1rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;

        .app-button {
            margin: 5px 0;
            border: 3px solid rgba(255, 255, 255, 0.9);
            border-radius: 7px;
            border-width: 2px;
            min-width: auto;

            &:after,
            &:before {
                left: -80%;
            }

            .internal {
                .icon {
                    margin: 0 0 0.5rem;
                    left: 0;
                }
            }

            &:hover {
                transform: scale(1.07);
                box-shadow: 0 0 35px rgba(0, 0, 0, 0.2);
            }

            &:hover:after {
                left: 50%;
            }

            &.random {
                background-color: $randomGameColor;
            }

            &.friend {
                background-color: $friendGameColor;
            }

            &.invite {
                background-color: $singleGameColor;

                .icon {
                    font-size: 1.6rem;
                }
            }

            &.join {
                background-color: #028e9b;
                .icon {
                    font-size: 1.6rem;
                }
            }

            &.shop {
                background-color: #028e9b;
            }

            &.rating {
                background-color: #9b5282;
            }

            &.profile {
                background-color: #cb5f1c;
            }
        }

        &.sub-menu {
            width: 100%;
            flex-direction: row;
            justify-content: space-between;
            flex-shrink: 0;

            .app-button {
                height: $topButtonHeight;
                width: 100%;
                flex: 1;
                margin: 0 0.5rem;

                &:first-child {
                    margin-left: 0;
                }

                &:last-child {
                    margin-right: 0;
                }

                .internal {
                    .icon {
                        width: 1.8rem;
                        margin-right: 10px;
                        margin-top: 2px;
                    }

                    .text {
                        font-size: 1.2rem;
                    }
                }
            }
        }

        &.main-menu {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 0.5rem;
            margin-top: 1rem;

            .app-button {
                width: 17rem;
                height: 3.5rem;

                .internal {
                    justify-content: left;
                    flex-direction: row;
                    height: 3.2rem;

                    .icon {
                        width: 2.4rem;
                        margin: 0 1rem 0 1rem;
                    }

                    .text {
                        font-size: 1.2rem;
                    }
                }
            }
        }
    }
}

@media (max-width: 1024px) {
    #page-default {
    }
}

@media (max-width: 768px) {
    #page-default {
        .app-logo {
            display: none;
        }

        .main-block {
            padding: 0.1rem 0;
            flex: 3;
            height: auto;

            & > .wrapper {
                background-color: transparent;
            }
        }

        .sub-menu.menu {
            .app-button {
                width: 100%;
                margin: 0;
                border: 0;
                border-radius: 0;
                flex: 1;

                .internal {
                    flex-direction: column;
                    height: $topButtonHeight;

                    .icon {
                        margin: 0 0 3px 0;
                        font-size: 1.5rem;
                        width: 1.5rem;
                        height: 1.5rem;
                    }
                    .text {
                        font-size: 0.9rem;
                        text-align: center;
                    }
                }
            }
        }
    }
}

@media (max-width: 320px) {
    #page-default {
        .main-block {
            .hello-block {
                .user-photo {
                    width: 8rem;
                    height: 8rem;
                }
            }
        }
    }
}
