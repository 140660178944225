.page-cross {
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 6px;
    border: 2px solid rgba(255, 255, 255, 0.25);
    width: 2rem;
    height: 2rem;
    position: absolute;
    right: 6px;
    top: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.5s ease;
    cursor: pointer;
    z-index: 1;

    &:hover {
        border: 2px solid rgba(255, 255, 255, 0.5);
        background-color: rgba(255, 255, 255, 0.25);
    }

    svg {
        font-size: 1.2rem;
    }
}
