.dialog-add-question {
    padding: 1rem 0 0.4rem;

    .question-user-info {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        .user-info {
            margin-right: 0.5rem;
            .user-photo {
                width: 2.5rem;
                height: 2.5rem;
            }

            .user-level {
                color: #fff;
            }
        }

        .user {
            display: flex;
            flex-direction: column;

            .id {
                margin-top: 0.15rem;
                font-size: 0.7rem;
                color: #888;

                svg {
                    font-size: 0.6rem;
                    margin-right: 0.15rem;
                }
            }
        }
    }

    .done {
        width: auto;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin: 1rem;
        min-width: 30rem;

        .success {
            display: flex;
            align-items: center;

            .icon {
                font-size: 6rem;
                color: rgba(0, 150, 80, 0.877);
            }
        }

        .title {
            font-size: 2rem;
            margin: 0.5rem;
        }

        .sub-title {
            text-align: center;
            margin: 0.2rem;
            width: 70%;
            font-size: 0.9rem;
        }
    }

    .content {
        max-width: 40rem;
        width: 90%;
        margin: 0 auto;
        padding-bottom: 0.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        & > p {
            width: 70%;
            text-align: center;
            margin: 0.5rem 0;
            font-size: 0.85rem;
            color: #777;
        }

        .error {
            height: 1rem;
            padding: 0.1 0 0rem;
            text-align: center;
            font-size: 0.8rem;

            span {
                top: 4px;
                position: relative;
                background-color: rgba(170, 0, 0, 0.8);
                color: #fff;
                padding: 0.05rem 0.3rem;
                border-radius: 4px;
            }
        }

        .question-block {
            margin-top: 0.5rem;
            margin-bottom: 0.2rem;
            width: 100%;
            display: flex;
            justify-content: center;

            .question {
                border-width: 2px;
                border-color: rgb(159, 194, 194);
                width: 100%;
                border-radius: 10px;
                height: 5rem;
                padding: 0.5rem 1rem;
                font-size: 0.85rem;
                color: #777;

                &::placeholder {
                    color: #c4c4c4;
                }
            }
        }
    }

    .answers {
        display: flex;
        flex-wrap: wrap;

        .answer {
            padding: 0.4rem 0.2rem 0.4rem 0.2rem;
            width: 50%;
            position: relative;

            & > span {
                color: #c4c4c4;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 0.7rem;
            }

            & > input[type='checkbox'] {
                position: absolute;
                right: 0.55rem;
                top: 45%;
                transform: translateY(-50%);
            }

            & > input[type='text'] {
                border-width: 2px;
                border-color: rgba(159, 194, 194, 0.5);
                border-radius: 4px;
                padding: 0.5rem 1.8rem 0.5rem 1.6rem;
                width: 100%;
                font-size: 0.85rem;
                color: rgb(78, 78, 78);
                -webkit-appearance: none;
                border-style: solid;

                &::placeholder {
                    color: #c4c4c4;
                }

                &.selected {
                    background-color: rgba(150, 177, 0, 0.1);
                }
            }
        }
    }

    .level {
        width: 100%;
        margin-bottom: 0.5rem;

        & > p {
            width: 100%;
            text-align: center;
            margin: 0.5rem 0;
            font-size: 0.9rem;
            color: #777;
        }
        .items {
            display: flex;
            flex-direction: row;

            .item {
                margin: 0 2px;
                width: 25%;
                text-align: center;
                padding: 0.5rem;
                background-color: #f3f3f3;
                border-radius: 5px;
                cursor: pointer;
                font-size: 0.9rem;
                transition: background-color 0.3s ease;

                &:hover {
                    background-color: #e1e1e1;
                }

                &.selected {
                    background-color: rgba(150, 177, 0, 0.5);
                }
            }
        }
    }

    .buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-top: 0.5rem !important;

        .management {
            flex-direction: row;

            .action-buttons {
                display: flex;
                align-items: center;
                justify-content: center;

                .app-button {
                    margin: 0 5px;
                }
            }
        }

        .app-button.delete {
            background-color: rgba(170, 0, 0, 0.8);
        }

        .fixing-helpers {
            display: flex;
            flex-direction: row;
            margin-bottom: 1rem;

            .sub-button {
                margin: 0.1rem 0.5rem;
            }
        }
    }
}
