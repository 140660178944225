@import '../../variables';

$aSideWidth: 6rem;

#home {
    width: $contentWidth;
    height: $contentHeight;
    margin: 0 auto;
    position: relative;
    padding-top: $iosPaddingTop;
    padding-bottom: $iosPaddingBottom;

    .animated {
        height: 100%;
    }

    .screen-fade-appear {
        opacity: 0.2;
        transform: scale(0.9);
    }

    .screen-fade-appear.screen-fade-appear-active {
        opacity: 1;
        transform: scale(1);
        transition: opacity 350ms ease-in, transform 350ms ease-in;
    }

    .screen-fade-enter {
        opacity: 0.2;
        transform: scale(0.9);
    }

    .screen-fade-enter.screen-fade-enter-active {
        opacity: 1;
        transition: opacity 350ms ease-in, transform 350ms ease-in;
        transform: scale(1);
    }

    header {
        position: relative;
        min-height: $headerHeight;

        .user-header {
            position: relative;
            display: flex;
            width: $contentWidth;
            margin: 0 auto;
            height: $headerHeight;
            z-index: 1;

            .user-info {
                margin-top: -6px;
                position: absolute;
            }

            .user-level-progress {
                margin-top: 1px;
                margin-left: 6.8rem;
                width: 14rem;
                height: 100%;
            }

            .user-money {
                margin-left: 1.2rem;
            }

            .game-block {
                right: 0;
                top: 0;
                bottom: 0;
            }
        }
    }

    footer {
        padding-left: $aSideWidth;
        height: $footerHeight;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &.is-game-started {
        .page-content {
            aside {
                overflow: hidden;
                width: 0;
                opacity: 0;
            }

            .content-wrapper {
                & > .content {
                    padding: 5px 0rem 0;
                    height: 100%;
                }
            }
        }

        footer {
            display: none;
        }
    }

    .page-content {
        padding-top: 60px;
        height: calc(100% - #{$headerHeight});
        display: flex;

        aside {
            width: $aSideWidth;
            background-color: rgba(255, 255, 255, 0.1);
            border-radius: 8px;
            height: 100%;

            #top-users {
                width: 6rem;
                height: 100%;
                display: flex;
                flex-direction: column;
            }
        }

        .content-wrapper {
            width: 100%;
            height: 100%;

            .content {
                width: 100%;
                height: 100%;
                padding-left: 2rem;
            }
        }
    }
}

@media (max-width: 1024px) {
    #home {
        footer {
            height: 2.2rem;
        }
    }
}

@media (max-width: 768px) {
    #home {
        width: auto;
        height: 100%;

        &.playing {
            & > header {
                display: none;
            }

            .page-content {
                height: 100%;
            }
        }

        & > header {
            .user-header {
                width: auto;
                padding: 0 10px;

                & > .user-info {
                    display: none;
                }

                & > .user-level-progress {
                    display: none;
                }

                & > .user-money {
                    font-size: 0.85rem;
                    height: 23px;
                    margin-right: 0.5rem;
                    padding: 0 0.2rem 0 0;

                    .icon-money {
                        width: 1.9rem;
                        margin-right: 0;
                    }
                    .text {
                        margin-left: 2px;
                        white-space: nowrap;
                    }
                }

                & > .game-block {
                    width: 100%;

                    .game-block-items {
                        justify-content: space-around;

                        .item {
                            .icon {
                                font-size: 1.3rem;
                            }
                        }
                    }
                }
            }
        }

        .page-content {
            padding-top: 0;

            & > .content-wrapper {
                & > .content {
                    padding-left: 0;
                }
            }

            & > aside {
                display: none;
            }
        }

        footer {
            display: none !important;
        }
    }
}

@media (max-width: 320px) {
    #home {
        & > header {
            .user-header {
                & > .user-level-progress {
                    width: 45%;
                }
            }
        }
    }
}
