.shop-group {
  margin-bottom: 1rem;

  .info-block {
    display: flex;
    margin-bottom: 1rem;

    .image {
      img {
        width: 2.5rem;
        height: 2.5rem;
      }
    }

    .info {
      margin-left: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: center;

      & > .title {
        font-size: 1.5rem;
      }

      & > .description {
        font-size: 0.85rem;
        margin-top: 3px;
        opacity: 0.85;
      }
    }
  }

  .items {

  }
}


@media (max-width: 320px)  {
  .shop-group {
    .info-block {
      margin-bottom: 0.7rem;

      .image {
        img {
          width: 2rem;
          height: 2rem;
        }
      }

      .info {
        & > .title {
          font-size: 1.2rem;
        }
      }
    }
  }
}
