.notification {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #626466;
  height: 100%;
  flex-direction: column;
  text-align: center;

  &.is-error {
    .title {
      color: #b93939;
    }
  }

  .title {
    font-size: 15px;
  }

  .description {
    margin-top: 2px;
    font-size: 14px;
  }
}