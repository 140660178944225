.money-item {
    display: flex;
    margin: 5px 0 7px;
    cursor: pointer;
    padding: 0.8rem;

    .icon {
        width: 5rem;
        padding: 4px;

        img {
            width: 100%;
        }
    }

    .description {
        padding: 0.85rem;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .title {
            font-size: 1rem;
            text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
        }

        .price {
            font-size: 0.75rem;
            margin-top: 0.3rem;

            .old-price {
                margin-left: 10px;
                text-decoration: line-through;
            }
        }

        .helper-text {
            margin-top: 0px;
            font-size: 0.75rem;
            position: relative;
            top: 10px;
            display: flex;
            align-items: center;
            color: rgba(255, 255, 255, 0.9);

            .giftbox {
                width: 16px;
                height: 16px;
                margin-right: 5px;
            }

            span {
                display: inline;
                position: relative;
                top: 1px;
                background: linear-gradient(0deg, #67b219, #86e01e);
                padding: 3px 7px;
                border-radius: 8px;
                text-shadow: -1px -1px 0px rgba(0, 0, 0, 0.2);
            }
        }
    }
}

@media (max-width: 768px) {
    .money-item {
        display: flex;
        margin: 0;
        cursor: pointer;
        padding: 0.8rem 0;
    }
}
