.progress-item {
    margin: 0;

    .value {
        font-size: 1.1rem;
        display: flex;
        justify-content: center;
        align-items: center;

        .icon {
            margin-left: 1px;
            margin-right: 0.2rem;
        }
    }

    .text {
        margin-top: 2px;
        font-size: 0.65rem;
        text-align: center;
        opacity: 0.8;
    }
}
