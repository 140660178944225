.dialog-multiplayer {
    .game-winner {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 0.5rem;

        .winner-wrapper {
            .name {
                text-align: center;
                margin-top: 0.5rem;
                font-size: 1.5rem;
            }

            .draw {
                text-align: center;
                font-size: 5rem;
                color: rgb(25, 180, 25);
            }
        }
    }

    .game-info {
        border-top: 1px solid rgba(0, 0, 0, 0.05);
        width: 55%;
        margin: 0 auto;
        margin-top: 1rem;
        padding-bottom: 0.5rem;
        padding-top: 0.5rem;

        .game-info-title {
            font-size: 0.8rem;
            padding: 0.5rem 0;
            text-align: center;
            color: #555;
        }

        .stat-block.points {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row !important;
            margin: 0 !important;
            color: rgb(224, 172, 0) !important;

            .icon {
                width: auto !important;
                margin-right: 5px;
            }

            span {
                font-size: 0.8rem !important;
            }
        }
    }
}
