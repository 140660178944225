.group-button {
    background-color: rgba(255, 255, 255, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.7);
    cursor: pointer;
    transition: background-color 0.4s ease;
    padding: 0.2rem 0.4rem;
    margin: 0;

    &.is-active {
        background-color: rgba(255, 255, 255, 0.5);

        & .internal {
            .text {
                text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
                color: rgba(255, 255, 255, 1);
            }
        }
    }

    & .internal {
        padding: 0.23rem 0.6rem;
        position: relative;
        z-index: 10;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .text {
            min-width: 50%;
            text-align: left;
            font-size: 0.8rem;
            color: rgba(255, 255, 255, 0.8);
        }
    }

    & .icon {
        width: 37px;
        left: -10px;
        position: relative;
    }

    &.small {
        font-size: 1rem;
    }

    &.medium {
        font-size: 1.1rem;

        .internal {
            height: 3.5rem;
        }
    }

    &.big {
        font-size: 1.3rem;
    }
}
