#helpers-panel {
    margin-top: 0.5rem;

    .helper-items {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    & > .text {
        margin-top: 4px;
        text-align: center;
        font-size: 0.7rem;
        color: rgba(255, 255, 255, 0.5);
    }
}

@media (max-width: 768px) {
    #helpers-panel {
        height: 4rem;
        margin-top: 0.5rem;
        margin-bottom: 0.2rem;
    }
}

@media (max-width: 320px) {
    #helpers-panel {
        height: 3.2rem;
        margin-top: 0.3rem;
        margin-bottom: 0;
    }
}
