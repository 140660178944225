#footer-block {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    height: 100%;

    .menu {
        display: flex;
        font-size: 0.75rem;

        a {
            margin: 0 0.5rem;
            color: #fff;
            text-decoration: none;
            vertical-align: middle;
            display: flex;
            justify-content: center;
            align-items: center;
            text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);

            .icon {
                font-size: 0.9rem;
                margin-right: 5px;
            }

            &:hover {
                text-shadow: 1px 1px 15px rgba(0, 0, 0, 0.3);
            }
        }
    }

    .copyright {
        margin-top: 2px;
        font-size: 0.7rem;
        color: rgba(255, 255, 255, 0.7);
        padding-bottom: 2px;
    }
}

@media (max-width: 768px) {
    #footer-block {
        display: none;
    }
}
