#page-money {
  .price-block {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .items {
      width: 85%;
      margin: 20px auto 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .money-item {
        width: 50%;
      }
    }

    .sub-info {
      text-align: center;
      font-size: 0.8rem;
      margin-top: 10px;
    }
  }
}

@media (max-width: 768px)  {
  #page-money {
    .price-block {
      overflow: auto;
      padding: 1rem;
      justify-content: flex-start;

      .items {
        width: 90%;
        margin: 0 auto;

        .money-item {
          width: 100%;
        }
      }

      .sub-info {
        clear: both;
        display: none;
      }
    }
  }
}
