@import './variables';
@import './yandex';

$colorMainButton: #69aa2f;

body,
html {
    width: 100vw;
    height: 100%;
    font-size: 100%;
}

#root {
    width: 100vw;
    height: 100%;

    /*background: #a32958;
    background: linear-gradient(170deg, #a32958, #922267);*/
    background: #2c7fa7;
    background: linear-gradient(-45deg, #0d72a5, #3284ad);
    background-repeat: repeat-x;
    color: #fff;
    font-family: 'Nunito', sans-serif;
    font-weight: 400;
}
input {
    font-family: 'Nunito', sans-serif;
}

button {
    font-family: 'Nunito', sans-serif;
}

textarea {
    font-family: 'Nunito', sans-serif;
}

* {
    box-sizing: border-box;
}

a {
    text-decoration: none;
}

h1 {
    text-align: center;
    font-size: 2rem;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
    margin-bottom: 10px;
}

h2 {
    text-align: left;
    font-size: 1.2rem;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
    margin-bottom: 10px;
}

::-webkit-scrollbar {
    width: 7px;
}

::-webkit-scrollbar-track {
    -webkit-border-radius: 5px;
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-thumb {
    -webkit-border-radius: 5px;
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.15);
}

::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(0, 0, 0, 0.15);
}

.black-line {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: calc(#{$headerHeight} + #{$iosPaddingTop});
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1;
}

.page {
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;

    &.with-background {
        background-color: rgba(255, 255, 255, 0.1);
        padding: 1.5rem 1rem;
        border-radius: 8px;
    }
}

.textBlock {
    height: 100%;
    color: #fff;
    overflow: auto;

    p {
        margin: 0.9rem 0;
        line-height: 1.2;
        font-size: 0.9rem;
        font-weight: normal;
    }
}

.hover-item {
    transition: background-color 0.3s;
    border-radius: 8px;

    &:hover {
        background-color: rgba(255, 255, 255, 0.1);
    }
}

.sub-button {
    font-size: 0.85rem;
    margin: 1rem 0 0.5rem;
    color: #7a7a7a;
    cursor: pointer;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    padding-bottom: 3px;

    &:hover {
        color: #444;
        border-bottom: 1px solid rgba(0, 0, 0, 0.18);
    }
}

/**
 * Common dialogs
 */

@keyframes star-animation {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}

.dialog-round-finished {
    background: linear-gradient(to top, #dce35b08, rgba(255, 224, 219, 1));
    padding: 0.5rem 0;

    &.is-winner {
        background: linear-gradient(to top, #dce35b08, #8abd0f47);
    }

    .game-info {
        .stat-block.points {
            display: flex;
            align-items: center;
            flex-direction: column;
            margin: 1.1rem 0 1.6rem;

            .icon {
                width: 57px;
                margin-right: 0.5rem;
                position: relative;
                animation: star-animation 1.3s infinite;
            }

            .value {
                font-size: 5.2rem;
                font-weight: bold;
            }

            & > span {
                color: rgba(0, 0, 0, 0.4);
                font-size: 0.75rem;
                margin-top: 2px;
            }

            &.points {
                .value {
                    color: #ffc853;
                    text-shadow: 1px 1px 0 rgb(220, 174, 72);
                    letter-spacing: 3px;
                }
            }
        }
    }

    .buttons {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin: 1rem 0 0;

        .app-button {
            min-width: 200px;
        }

        .sub-button {
            margin-top: 0.75rem;
        }
    }
}

@media (max-width: 768px) {
    html,
    body,
    input {
        font-size: 90%;
    }

    h1 {
        font-size: 1.3rem;
    }

    .page {
        &.with-background {
            padding: 1rem 0.5rem 0 0.5rem;
            border-radius: 0;
        }
    }

    .black-line.is-hidden {
        display: none;
    }

    .__react_component_tooltip {
        display: none !important;
    }

    #you-level-tooltip {
        display: none !important;
    }
}

@media (max-width: 320px) {
    html {
        font-size: 85%;
    }

    h1 {
        font-size: 1.6rem;
    }
}
