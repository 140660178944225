.rating-user {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    padding: 0.45rem 0.7rem;
    margin-right: 10px;

    .rating-position {
        display: flex;
        text-align: right;
        align-items: center;
        font-size: 2.7rem;
        min-width: 20%;
        padding: 0 1.5rem 0 0.5rem;
        position: relative;
        justify-content: flex-end;
    }

    .user-info {
        margin-right: 1rem;
    }

    .user {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .name {
            font-size: 1.2rem;
        }

        .games-info {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin-top: 5px;

            .info-item {
                margin-right: 1rem;
                display: flex;
                align-items: center;
                opacity: 0.8;

                &.score {
                    .icon {
                        top: -1px;
                    }
                }

                &.games-win {
                    .icon {
                        top: 0;
                    }
                }

                &.games-played {
                    .icon {
                        top: 0;
                    }
                }

                .icon {
                    font-size: 0.8rem;
                    margin-right: 4px;
                    position: relative;
                }

                span {
                    display: block;
                    font-size: 0.8rem;
                    white-space: nowrap;
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .rating-user {
        padding-left: 0;
        padding-right: 0;

        .rating-position {
            padding-right: 1.1rem;
            padding-left: 0;
            font-size: 2rem;
            min-width: 15%;
        }

        .user {
            .name {
                font-size: 1.1rem;
            }
        }
    }
}

@media (max-width: 320px) {
    .rating-user {
        .rating-position {
            padding-right: 0.6rem;
        }

        .user {
            .name {
                font-size: 1rem;
            }

            .games-info {
                .info-item {
                    span {
                        font-size: 0.7rem;
                        white-space: nowrap;
                    }
                }
            }
        }
    }
}
