$contentWidth: 900px;
$contentHeight: 660px;

$headerHeight: 3.2rem;
$footerHeight: 2.6rem;

$profilePageWidth: 18rem;

$randomGameColor: #69aa2f;
$friendGameColor: #3f84d1;
$singleGameColor: #b7384d;
$inviteColor: #9b5282;
$iosPaddingTop: env(safe-area-inset-top);
$iosPaddingBottom: env(safe-area-inset-bottom);

@media (max-width: 768px) {
    $contentWidth: auto;
    $contentHeight: 98%;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}
