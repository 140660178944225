$helperTextColor: rgba(255, 255, 255, 0.8);
$helperTextColorActive: rgba(255, 255, 255, 1);

.money-info {
    &:before {
        content: '';
        //border-left: 2px solid #fff;
        //border-top: 2px solid #fff;
        position: absolute;
        background-color: rgba(255, 255, 255, 1);
        top: -5px;
        height: 12px;
        width: 12px;
        transform: rotate(45deg);
    }

    position: absolute !important;
    font-size: 0.75rem;
    background-color: rgba(255, 255, 255, 1);
    bottom: -60px;
    left: -0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #444;
    padding: 0.6rem 0.6rem;
    position: relative;
    border-radius: 5px;
    min-width: 9rem;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);

    &:hover {
        .money-info-close {
            color: #444;
        }
    }

    .money-info-text {
        text-align: center;
    }

    .money-info-close {
        position: absolute;
        top: 4px;
        right: 4px;
        font-size: 0.8rem;
        color: #aaa;
        transition: color 0.3s ease;
    }
}

.user-money {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    justify-self: center;
    align-self: center;
    background: linear-gradient(180deg, #86e01e, #67b219);
    border: 1px solid #fff;
    border-radius: 4px;
    padding: 0 15px 0 0;
    height: 57%;
    transition: background 0.5s ease;
    position: relative;

    .icon-money {
        width: 2.5rem;
        margin-right: 3px;
        margin-left: -5px;
    }

    .text {
        margin-left: 8px;
    }

    &:hover {
        background: linear-gradient(180deg, #67b219, #86e01e);
    }

    &.no-action {
        cursor: default;
        pointer-events: none;
    }
}

.game-block {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;

    .game-block-items {
        display: flex;
        width: 100%;
        justify-content: space-around;
    }

    .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        margin-left: 1rem;
        text-decoration: none;

        .icon {
            font-size: 1.5rem;
        }

        &:hover {
            span {
                color: $helperTextColorActive;
            }
        }

        span {
            font-size: 0.6rem;
            margin-top: 2px;
            color: $helperTextColor;
            transition: color 0.3s ease;
        }
    }
}

.app-vk-app {
    .game-block {
        overflow: hidden;
        padding-right: 95px;

        .game-block-items {
            justify-content: flex-start !important;
            overflow: hidden;
            width: auto;
        }

        .game-invite {
            display: none;
        }

        .game-logout {
            display: none;
        }
    }
}

@media (max-width: 320px) {
    .app-vk-app {
        .game-block {
            .game-block-items {
            }
        }
    }
}
