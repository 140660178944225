.dialog-problem-questions {
    padding: 1rem 0 0.4rem;

    .content {
        max-width: 40rem;
        min-height: 400px;
        min-width: 630px;
        width: 90%;
        margin: 0 auto;
        padding-bottom: 0.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .questions {
            height: 400px;
            overflow-y: auto;
            width: 100%;

            .question {
                padding: 10px 7px;
                transition: background ease 0.2s;
                display: flex;
                flex-direction: column;
                flex: 1;

                &:nth-child(odd) {
                    background: #f9f9f9;
                }

                &:hover {
                    background: #f1f1f1;
                }

                .title-block {
                    display: flex;
                    flex-direction: column;
                    flex: 1;

                    .sub-info {
                        margin-bottom: 6px;
                        font-size: 11px;
                        color: #888;

                        .saved {
                            font-size: 11px;
                            color: green;
                        }

                        span {
                            margin-right: 5px;
                        }
                    }

                    .title {
                        display: flex;
                        align-items: center;
                        font-size: 0.92rem;
                        line-height: 1.05rem;
                    }

                    .answers {
                        margin: 5px 0;
                        display: flex;
                        flex-wrap: wrap;

                        .answer {
                            font-size: 0.75rem;
                            width: 50%;
                            padding: 0 5px 5px 0;
                        }
                    }

                    .message {
                        margin-top: 6px;

                        textarea {
                            width: 100%;
                        }
                    }
                }

                .action {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 5px 0 0;
                    flex-direction: row;

                    .app-button {
                        margin: 0 5px;

                        &.decline {
                            background-color: rgb(170, 43, 43);
                        }

                        &.edit {
                            background-color: rgb(187, 140, 39);
                        }
                    }
                }
            }
        }
    }

    .buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-top: 0.5rem !important;
    }
}
