#page-shop {
    .shop-container {
        margin: 1.5rem auto;
        width: 350px;
    }
}

@media (max-width: 768px) {
    #page-shop {
        .shop-container {
            width: auto;
            padding: 0 1rem;
            margin: 0.5rem auto;
        }
    }
}
