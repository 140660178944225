.dialog-update-profile {
    padding: 1rem 0 1.5rem;

    .done {
        width: auto;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin: 1rem;
        min-width: 20rem;

        .success {
            display: flex;
            align-items: center;

            .icon {
                font-size: 6rem;
                color: rgba(0, 150, 80, 0.877);
            }
        }

        .title {
            font-size: 2rem;
            margin: 0.5rem;
        }

        .sub-title {
            text-align: center;
            margin: 0.2rem;
            width: 70%;
            font-size: 0.9rem;
        }
    }

    .content {
        max-width: 20rem;
        width: 90%;
        margin: 0 auto;
        padding-bottom: 0.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        & > p {
            width: 100%;
            text-align: center;
            margin: 0.5rem 0 0;
            font-size: 0.85rem;
            color: #777;
        }

        .error {
            height: 1rem;
            padding: 0.1 0 0rem;
            text-align: center;
            font-size: 0.8rem;
            margin-bottom: 0.5rem;
            margin-top: 0.2rem;

            span {
                top: 4px;
                position: relative;
                background-color: rgba(170, 0, 0, 0.8);
                color: #fff;
                padding: 0.05rem 0.3rem;
                border-radius: 4px;
            }
        }
    }

    .user-details {
        display: flex;
        flex-wrap: wrap;

        .photo-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 0.5rem;
            width: 100%;
            flex-direction: column;

            .user-photo {
                width: 8rem;
                height: 8rem;
            }

            .photo-uploader {
                margin: 0.5rem;
                cursor: pointer;
                background-color: #00a9a1;
                padding: 5px 8px;
                border-radius: 5px;
                color: #fff;
                display: inline-block;
                position: relative;

                &:hover {
                    background-color: darken(#00a9a1, 5%);
                }

                input {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    z-index: 1;
                    opacity: 0;
                    cursor: pointer;
                }
            }
        }

        .form-inputs {
            margin-bottom: 0rem;
            width: 100%;
        }

        .input {
            padding: 0.4rem 0.2rem 0.4rem 0.2rem;
            width: 80%;
            position: relative;
            margin: 0 auto;

            & > span {
                color: #c4c4c4;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 0.7rem;
            }

            & > input[type='text'] {
                border-width: 2px;
                border-color: rgba(159, 194, 194, 0.5);
                border-radius: 4px;
                padding: 0.5rem 1rem 0.5rem 1rem;
                width: 100%;
                font-size: 0.85rem;
                color: rgb(78, 78, 78);
                -webkit-appearance: none;
                border-style: solid;

                &::placeholder {
                    color: #c4c4c4;
                }
            }
        }
    }

    .buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-top: 0.5rem !important;

        .fixing-helpers {
            display: flex;
            flex-direction: row;
            margin-bottom: 1rem;

            .sub-button {
                margin: 0.1rem 0.5rem;
            }
        }
    }
}
