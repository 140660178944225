$borderRadius: 5px;

.progress-bar {
    overflow: hidden;
    border-radius: $borderRadius;
    width: 100%;
    height: 1.3rem;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 2px;
    position: relative;
    text-align: center;
    justify-content: center;

    .progress {
        height: 100%;
        border-radius: $borderRadius - 1;
        background-color: #86e01e;
        transition: background-color 1.5s ease-out, width 0.3s ease;

        &.warn {
            background-color: #f2d31b !important;
        }

        &.danger {
            background-color: #f27011 !important;
        }

        &.critical {
            background-color: #f63a0f !important;
        }
    }

    .points {
        padding-top: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 0;
        left: 0;
        top: 0;
        bottom: 0;
        font-size: 0.7rem;
        text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.9);
    }
}
