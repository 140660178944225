@keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

#page-game-loading {
  justify-content: center;
  align-items: center;

  .loading-container {
    text-align: center;

    .loading-block {
      height: 20px;
    }

    .title {
      font-size: 2rem;
      margin-top: 10px;
      margin-bottom: 3px;
    }

    .description {
      margin-bottom: 1rem;
      opacity: 0.8;
      font-size: 0.9rem;

      animation: flickerAnimation 2s infinite;
    }
  }

  .additional {
    font-size: 0.8rem;
    line-height: 1rem;
    margin: 1rem 0 0.5rem;

    a {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}