#questions-answers {
    display: flex;
    flex-direction: column;
    width: 100%;

    .question-wrapper {
        height: 55%;
        padding: 1rem 0 0.5rem;

        .question {
            height: 100%;
            background-color: rgba(0, 0, 0, 0.1);
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 10px 10px 0 0;
            position: relative;

            .author-name {
                font-size: 0.7rem;
                left: 0.4rem;
                top: 0.4rem;
                color: rgba(255, 255, 255, 0.3);
                position: absolute;

                svg {
                    margin-right: 0.25rem;
                }
            }

            .rate-emotions {
                position: absolute;
                font-size: 0.7rem;
                left: 50%;
                transform: translateX(-50%);
                top: 0;

                display: flex;
                flex-direction: row;

                & > div {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 0 1px;
                    border-radius: 0 0 100% 100%;
                    padding: 0 5px;
                    min-width: 3rem;
                    height: 1.2rem;
                    transition: height 0.3s ease;
                    color: rgba(255, 255, 255, 0.6);
                    cursor: pointer;

                    &:hover {
                        color: rgba(255, 255, 255, 0.9);
                        height: 1.35rem;
                    }
                }

                & > .like {
                    background-color: rgba(103, 178, 25, 0.5);
                }

                & > .dislike {
                    background-color: rgba(175, 64, 0, 0.5);
                }
            }

            span {
                width: 95%;
                display: block;
                margin: 0 auto;
                text-align: center;
                color: #fff;
                font-size: 1.35rem;
                line-height: 1.3;
            }

            .question-problem {
                position: absolute;
                font-size: 1rem;
                right: 5px;
                top: 5px;
                color: rgba(255, 255, 255, 0.5);
                cursor: pointer;

                &:hover {
                    color: rgba(255, 255, 255, 0.9);
                }
            }
        }

        .progress-block {
            padding: 0;
            position: relative;
            top: -10px;

            .progress-bar {
                border-radius: 3px;
                height: 10px;
                padding: 1px;
                background-color: rgba(0, 0, 0, 0.2);

                .progress {
                    border-radius: 2px;
                }
            }
        }
    }

    .answers {
        height: 45%;
        padding: 0.35rem 0 0;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 0.8rem;

        .answer-wrapper {
            width: 100%;
            height: 100%;

            &.is-active {
                .answer {
                    &:hover {
                        background-color: rgba(0, 0, 0, 0.15);
                        transform: scale(1.02);

                        .letter {
                            color: rgba(255, 255, 255, 0.25);
                        }
                    }
                }
            }

            &.is-disabled {
                cursor: auto;
            }

            &.is-selected {
                .answer {
                    background-color: rgba(255, 166, 0, 0.4);
                }
            }

            &.is-correct {
                .answer {
                    background-color: rgba(103, 178, 25, 0.65) !important;
                }
            }

            .answer {
                position: relative;
                border-radius: 10px;
                padding: 0.5rem 1.5rem;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.1);
                cursor: pointer;
                transition: background 0.3s ease, transform 0.3s ease;

                .text {
                    font-size: 1.2rem;
                    text-align: center;
                }

                .letter {
                    position: absolute;
                    left: 0.5rem;
                    top: 0.45rem;
                    font-size: 1.4rem;
                    color: rgba(255, 255, 255, 0.2);
                }
            }
        }
    }
}

@media (max-width: 768px) {
    #questions-answers {
        .question-wrapper {
            padding: 1.5rem 1rem 0.2rem;

            .question {
                .author-name {
                    font-size: 0.55rem;

                    svg {
                        font-size: 0.55rem;
                    }
                }

                span {
                    font-size: 1.2rem;
                    line-height: 1.05;
                }
            }

            .rate-emotions {
                & > div {
                }
            }
        }

        .answers {
            padding: 0.35rem 0.5rem 0.35rem;

            .answer-wrapper {
                .answer {
                    .text {
                        font-size: 1rem;
                    }
                }
            }
        }
    }
}

@media (max-width: 320px) {
    #questions-answers {
        .question-wrapper {
            .question {
                .author-name {
                    display: none;
                }
                span {
                    font-size: 1.15rem;
                }
            }

            .rate-emotions {
                display: none !important;
            }
        }

        .answers {
            .answer-wrapper {
                .answer {
                    .text {
                        font-size: 0.9rem;
                    }
                }
            }
        }
    }
}
